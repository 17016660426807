import { financialApiInstance } from '@helpers/request/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { ITagsPagination } from '@apptypes/pagination';
import { InstanceError } from '@apptypes/instance';
import { ITag, ICreateTagPayload, IUpdateTagPayload } from '../types/tags';

export const getAll = async (page = 1, limit = 10): Promise<ITagsPagination<ITag>> => {
  try {
    const { data } = await financialApiInstance.get('/api/v1/tags', {
      params: {
        page,
        limit,
      },
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Tags não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getById = async (id: string): Promise<ITag> => {
  try {
    const { data } = await financialApiInstance.get(`/api/v1/tags/${id}`);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Tag não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: ICreateTagPayload): Promise<ITag> => {
  try {
    const { data } = await financialApiInstance.post('/api/v1/tags', payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Dados inválidos',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (id: string, payload: IUpdateTagPayload): Promise<ITag> => {
  try {
    const { data } = await financialApiInstance.put(`/api/v1/tags/${id}`, payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Tag não encontrada',
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Dados inválidos',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const remove = async (id: string): Promise<void> => {
  try {
    await financialApiInstance.delete(`/api/v1/tags/${id}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Tag não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
