import { AxiosResponse } from 'axios';
import { InstanceError } from '@apptypes/instance';

import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { financialApiInstance } from '@helpers/request/instance';
import { generateToQueryString } from '@helpers/queryString';

import { ICategory, ICategoriesRoot, ICreateCategory, ISubCategory } from '../types/categories';

export const getAllCategories = async (
  page = 1,
  limit = Number.MAX_SAFE_INTEGER,
  filterItems?: any,
): Promise<ICategoriesRoot> => {
  try {
    const queryString = generateToQueryString({
      page,
      filterItems,
      otherParams: `&limit=${limit}`,
    });
    const { data } = await financialApiInstance.get(`/api/v1/categories${queryString}`);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Categorias não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getCategoryById = async (id: string | undefined): Promise<ICategory> => {
  try {
    const { data } = await financialApiInstance.get(`/api/v1/categories/${id}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Categoria não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const createCategory = async (createData: ICreateCategory): Promise<ICreateCategory> => {
  try {
    const { data } = await financialApiInstance.post('/api/v1/categories', createData);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Erro ao criar categoria',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const updateCategory = async (
  id: string | undefined,
  updateData: ICreateCategory | undefined,
): Promise<ICategory> => {
  try {
    const { data } = await financialApiInstance.put(`/api/v1/categories/${id}`, updateData);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Erro ao atualizar categoria',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteCategory = async (
  id: string,
  destinyCategoryID: string,
): Promise<typeof AxiosResponse> => {
  try {
    const { data } = await financialApiInstance.delete(`/api/v1/categories/${id}`, {
      data: { destinyCategoryID },
    });

    return data;
  } catch (error: any) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Categoria não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

// * [SubCategories] * //
export const createSubCategory = async (
  categoryId: string,
  name: string,
): Promise<typeof AxiosResponse> => {
  try {
    const { data } = await financialApiInstance.post('/api/v1/subcategories', {
      categoryId,
      name,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Erro ao criar subcategoria',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getSubCategoryById = async (id: string): Promise<ISubCategory> => {
  try {
    const { data } = await financialApiInstance.get(`/api/v1/subcategories/${id}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Subcategoria não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const updateSubCategory = async (
  subCategoryId: string,
  categoryId: string,
  name: string,
): Promise<ISubCategory> => {
  try {
    const { data } = await financialApiInstance.put(`/api/v1/subcategories/${subCategoryId}`, {
      categoryId,
      name,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Erro ao atualizar subcategoria',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteSubCategory = async (id: string): Promise<typeof AxiosResponse> => {
  try {
    const { data } = await financialApiInstance.delete(`/api/v1/subcategories/${id}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Subcategoria não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
