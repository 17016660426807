export const TRANSACTION_TYPE_OPTIONS = [
  { value: 'income', label: 'Receita' },
  { value: 'expense', label: 'Despesa' },
];

export const PERIODICITY_OPTIONS = [
  { value: 'D', label: 'Diário' },
  { value: 'W', label: 'Semanal' },
  { value: 'B', label: 'Quinzenal' },
  { value: 'M', label: 'Mensal' },
  { value: 'Q', label: 'Trimestral' },
  { value: 'S', label: 'Semestral' },
  { value: 'Y', label: 'Anual' },
];
