import React, { useCallback, useState } from 'react';
import { Button, InputGroup, Modal, ModalBody, FormFeedback, Row, Col } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { moneyTransform } from 'helpers/transforms/money';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import 'flatpickr/dist/themes/light.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import * as S from './styles';

import { IManageTransactionsModalProps } from './types';
import { NumericFormat } from 'react-number-format';

import { TRANSACTION_TYPE_OPTIONS, PERIODICITY_OPTIONS } from '../../../../constants/transactions';
import { TRANSACTION_VALIDATION_MESSAGES } from '../../../../constants/validations';
import { useLoadOptions } from '../../../../hooks/useLoadOptions';
import classNames from 'classnames';
import { ITransactionForm } from '../../../../types/transactions';
import { createIncome } from '../../../../services/transactions';

export const ManageTransactionsModal = ({ showModal, toggle }: IManageTransactionsModalProps) => {
  const [transactionType, setTransactionType] = useState('expense');
  const [accordionOpen, setAccordionOpen] = useState<string[]>([]);
  const [switchState, setSwitchState] = useState({
    fixed: false,
    recurring: false,
  });

  const queryClient = useQueryClient();
  const { loadTagOptions, loadWalletOptions, loadCategoryOptions, loadPaymentMethodOptions } =
    useLoadOptions(transactionType);

  const createIncomeMutation = useMutation({
    mutationFn: createIncome,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['incomes'] });

      toast.success('Receita criada com sucesso!', {
        autoClose: 750,
        theme: 'colored',
        onClose: () => toggle(),
      });
    },
    onError: () => {
      toast.error('Erro ao criar receita. Tente novamente.', {
        autoClose: 1500,
        theme: 'colored',
      });
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      amount: 0,
      paymentDate: moment().format('YYYY-MM-DD'),
      wallet: {
        value: '',
        label: '',
      },
      category: {
        value: '',
        label: '',
      },
      paymentMethod: {
        value: '',
        label: '',
      },
      fixed: false,
      recurring: false,
      periodicity: '',
      installments: 1,
      observation: '',
      tags: [],
      conciliated: false,
      firstInstallmentDate: moment().format('YYYY-MM-DD'),
    },
    validationSchema: Yup.object({
      description: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.DESCRIPTION_REQUIRED),
      amount: Yup.number().required(TRANSACTION_VALIDATION_MESSAGES.AMOUNT_REQUIRED),
      paymentDate: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.PAYMENT_DATE_REQUIRED),
      wallet: Yup.object({
        value: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.WALLET_REQUIRED),
      }),
      category: Yup.object({
        value: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.CATEGORY_REQUIRED),
      }),
      paymentMethod: Yup.object({
        value: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.PAYMENT_METHOD_REQUIRED),
      }),
      periodicity: Yup.string().when('recurring', {
        is: true,
        then: Yup.string().required(TRANSACTION_VALIDATION_MESSAGES.PERIODICITY_REQUIRED),
      }),
      installments: Yup.number().when('recurring', {
        is: true,
        then: Yup.number()
          .min(2, TRANSACTION_VALIDATION_MESSAGES.INSTALLMENTS_MIN)
          .required(TRANSACTION_VALIDATION_MESSAGES.INSTALLMENTS_REQUIRED),
      }),
      firstInstallmentDate: Yup.string().when('recurring', {
        is: true,
        then: Yup.string().required('Data da primeira parcela é obrigatória'),
      }),
    }),
    onSubmit: (values: ITransactionForm) => {
      createIncomeMutation.mutate(values);
    },
  });

  console.log(validation.errors);

  const handleAccordionOpen = useCallback((id: string) => {
    setAccordionOpen((prevState) => {
      if (prevState.includes(id)) {
        return [];
      }

      return [id];
    });
  }, []);

  const handleSwitchChange = useCallback((switchName: 'fixed' | 'recurring') => {
    setSwitchState((prev) => ({
      fixed: switchName === 'fixed' ? !prev.fixed : false,
      recurring: switchName === 'recurring' ? !prev.recurring : false,
    }));
  }, []);

  const calculateInstallmentValue = useCallback(() => {
    if (!validation.values.amount || validation.values.installments <= 0) return 'R$ 0,00';
    return moneyTransform(validation.values.amount / validation.values.installments);
  }, [validation.values.amount, validation.values.installments]);

  return (
    <Modal isOpen={showModal} toggle={toggle} centered size="xl">
      <S.ManageTransactionsModalHeader toggle={toggle}>
        <S.TransactionTypeInput
          type="select"
          name="transactionType"
          value={transactionType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTransactionType(e.target.value)}
        >
          {TRANSACTION_TYPE_OPTIONS.map((option) => (
            <S.FieldOption key={option.value} value={option.value}>
              {option.label}
            </S.FieldOption>
          ))}
        </S.TransactionTypeInput>
      </S.ManageTransactionsModalHeader>

      <ModalBody>
        <form onSubmit={validation.handleSubmit}>
          <Row>
            <Col>
              <S.FormFieldGroup>
                <S.FieldLabel for="description">Descrição</S.FieldLabel>
                <S.InputField
                  type="text"
                  name="description"
                  id="description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description}
                  invalid={!!(validation.touched.description && validation.errors.description)}
                />
                {validation.touched.description && validation.errors.description && (
                  <FormFeedback>{validation.errors.description}</FormFeedback>
                )}
              </S.FormFieldGroup>

              <S.FieldWrapper>
                <S.FormFieldGroup>
                  <S.FieldLabel for="amount">Valor</S.FieldLabel>
                  <InputGroup>
                    <NumericFormat
                      id="amount"
                      name="amount"
                      className="form-control"
                      decimalSeparator=","
                      thousandSeparator="."
                      placeholder="Digite um valor"
                      prefix="R$ "
                      decimalScale={2}
                      onValueChange={({ value }: any) => validation.setFieldValue('amount', value)}
                    />
                  </InputGroup>
                </S.FormFieldGroup>

                <S.FormFieldGroup>
                  <S.FieldLabel for="paymentDate">Data de pagamento</S.FieldLabel>
                  <Flatpickr
                    id="paymentDate"
                    name="paymentDate"
                    value={validation.values.paymentDate}
                    onChange={([selectedDate]) =>
                      validation.setFieldValue('paymentDate', selectedDate)
                    }
                    options={{
                      dateFormat: 'd/m/Y',
                      locale: Portuguese,
                      enableTime: false,
                    }}
                    className="form-control"
                  />
                </S.FormFieldGroup>
              </S.FieldWrapper>

              <S.FieldWrapper>
                <S.FormFieldGroup>
                  <S.FieldLabel for="wallet">Conta/Cartão</S.FieldLabel>
                  <AsyncSelect
                    name="wallet"
                    className="react-select-container"
                    classNames={{
                      singleValue: () => 'react-select-single-value',
                      placeholder: () => 'react-select-placeholder',
                    }}
                    loadOptions={loadWalletOptions}
                    value={validation.values.wallet}
                    onChange={(newValue) => validation.setFieldValue('wallet', newValue)}
                    placeholder="Selecionar conta..."
                    defaultOptions
                    cacheOptions
                  />
                </S.FormFieldGroup>

                <S.FormFieldGroup>
                  <S.FieldLabel for="category">Categoria</S.FieldLabel>
                  <AsyncSelect
                    name="category"
                    className="react-select-container"
                    classNames={{
                      singleValue: () => 'react-select-single-value',
                      placeholder: () => 'react-select-placeholder',
                    }}
                    loadOptions={loadCategoryOptions}
                    value={validation.values.category}
                    onChange={(newValue) => validation.setFieldValue('category', newValue)}
                    placeholder="Selecionar categoria..."
                    defaultOptions
                    cacheOptions
                  />
                </S.FormFieldGroup>
              </S.FieldWrapper>

              <S.FormFieldGroup>
                <S.FieldLabel for="paymentMethod">Forma de pagamento</S.FieldLabel>
                <AsyncSelect
                  name="paymentMethod"
                  className="react-select-container"
                  classNames={{
                    singleValue: () => 'react-select-single-value',
                    placeholder: () => 'react-select-placeholder',
                  }}
                  loadOptions={loadPaymentMethodOptions}
                  value={validation.values.paymentMethod}
                  onChange={(newValue) => validation.setFieldValue('paymentMethod', newValue)}
                  placeholder="Selecionar forma de pagamento..."
                  defaultOptions
                  cacheOptions
                />
                {validation.errors.paymentMethod?.value && (
                  <FormFeedback>{validation.errors.paymentMethod.value}</FormFeedback>
                )}
              </S.FormFieldGroup>
            </Col>
            <Col sm={5}>
              <S.ConfigurationTitle>Configurações</S.ConfigurationTitle>
              <S.AccordionWrapper>
                <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
                  <S.TransactionAccordionItem>
                    <S.TransactionAccordionHeader targetId="repeat">
                      <div>
                        <S.Icon className="mdi mdi-repeat" />
                        Repetir
                      </div>
                      <S.Icon
                        className={classNames('mdi', {
                          'mdi-menu-up': accordionOpen.includes('repeat'),
                          'mdi-menu-down': !accordionOpen.includes('repeat'),
                        })}
                      />
                    </S.TransactionAccordionHeader>
                    <S.TransactionAccordionBody accordionId="repeat">
                      <S.FormFieldGroup switch>
                        <S.InputField
                          type="switch"
                          role="switch"
                          name="fixed"
                          id="fixed"
                          checked={switchState.fixed}
                          onChange={() => handleSwitchChange('fixed')}
                          placeholder=""
                        />
                        <S.FieldLabel check for="fixed">
                          é uma despesa fixa
                        </S.FieldLabel>
                      </S.FormFieldGroup>

                      <S.FormFieldGroup switch marginTop={8}>
                        <S.InputField
                          type="switch"
                          role="switch"
                          name="recurring"
                          id="recurring"
                          checked={switchState.recurring}
                          onChange={() => handleSwitchChange('recurring')}
                          placeholder=""
                        />
                        <S.FieldLabel check for="recurring">
                          é uma despesa parcelada
                        </S.FieldLabel>
                      </S.FormFieldGroup>

                      {switchState.recurring && (
                        <S.RecurringWrapper>
                          <Row>
                            <Col>
                              <S.FormFieldGroup>
                                <S.FieldLabel for="periodicity">Periodicidade</S.FieldLabel>
                                <S.InputField
                                  type="select"
                                  name="periodicity"
                                  id="periodicity"
                                  onChange={validation.handleChange}
                                  value={validation.values.periodicity}
                                >
                                  <option value="">Selecione...</option>
                                  {PERIODICITY_OPTIONS.map((option) => (
                                    <S.FieldOption key={option.value} value={option.value}>
                                      {option.label}
                                    </S.FieldOption>
                                  ))}
                                </S.InputField>
                                {validation.touched.periodicity &&
                                  validation.errors.periodicity && (
                                    <FormFeedback>{validation.errors.periodicity}</FormFeedback>
                                  )}
                              </S.FormFieldGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <S.FormFieldGroup>
                                <S.FieldLabel for="installments">Número de Parcelas</S.FieldLabel>
                                <S.InputField
                                  type="text"
                                  name="installments"
                                  id="installments"
                                  value={validation.values.installments}
                                  onChange={(e) => {
                                    let value = Number(e.target.value);
                                    if (!value) {
                                      value = 1;
                                    }
                                    validation.setFieldValue('installments', value);
                                  }}
                                  invalid={
                                    !!(
                                      validation.touched.installments &&
                                      validation.errors.installments
                                    )
                                  }
                                />
                                {validation.touched.installments &&
                                  validation.errors.installments && (
                                    <FormFeedback>{validation.errors.installments}</FormFeedback>
                                  )}
                              </S.FormFieldGroup>
                            </Col>
                            <Col xs={4}>
                              <S.FormFieldGroup>
                                <S.FieldLabel>Valor por parcela</S.FieldLabel>
                                <S.InstallmentValue>
                                  {calculateInstallmentValue()}
                                </S.InstallmentValue>
                              </S.FormFieldGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <S.FormFieldGroup>
                                <S.FieldLabel for="firstInstallmentDate">
                                  Data primeira parcela
                                </S.FieldLabel>
                                <Flatpickr
                                  id="firstInstallmentDate"
                                  name="firstInstallmentDate"
                                  value={validation.values.firstInstallmentDate}
                                  onChange={([selectedDate]) =>
                                    validation.setFieldValue('firstInstallmentDate', selectedDate)
                                  }
                                  options={{
                                    dateFormat: 'd/m/Y',
                                    locale: Portuguese,
                                    enableTime: false,
                                  }}
                                  className="form-control"
                                />
                              </S.FormFieldGroup>
                            </Col>
                          </Row>
                        </S.RecurringWrapper>
                      )}
                    </S.TransactionAccordionBody>
                  </S.TransactionAccordionItem>
                </S.TransactionAccordion>

                <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
                  <S.TransactionAccordionItem>
                    <S.TransactionAccordionHeader targetId="observation">
                      <div>
                        <S.Icon className="mdi mdi-message-text" />
                        Observação
                      </div>
                      <S.Icon
                        className={classNames('mdi', {
                          'mdi-menu-up': accordionOpen.includes('observation'),
                          'mdi-menu-down': !accordionOpen.includes('observation'),
                        })}
                      />
                    </S.TransactionAccordionHeader>
                    <S.TransactionAccordionBody accordionId="observation">
                      <S.FormFieldGroup>
                        <S.InputField
                          type="textarea"
                          name="observation"
                          id="observation"
                          placeholder=""
                        />
                      </S.FormFieldGroup>
                    </S.TransactionAccordionBody>
                  </S.TransactionAccordionItem>
                </S.TransactionAccordion>

                <S.TransactionAccordion flush open={accordionOpen} toggle={handleAccordionOpen}>
                  <S.TransactionAccordionItem>
                    <S.TransactionAccordionHeader targetId="tags">
                      <div>
                        <S.Icon className="mdi mdi-tag-multiple" />
                        Tags
                      </div>
                      <S.Icon
                        className={classNames('mdi', {
                          'mdi-menu-up': accordionOpen.includes('tags'),
                          'mdi-menu-down': !accordionOpen.includes('tags'),
                        })}
                      />
                    </S.TransactionAccordionHeader>
                    <S.TransactionAccordionBody accordionId="tags">
                      <S.FormFieldGroup>
                        <AsyncSelect
                          isMulti
                          name="tags"
                          className="react-select-container"
                          classNames={{
                            multiValue: () => 'react-select-multi-value',
                            placeholder: () => 'react-select-placeholder',
                          }}
                          loadOptions={loadTagOptions as any}
                          value={validation.values.tags}
                          onChange={(newValue) => validation.setFieldValue('tags', newValue)}
                          placeholder="Selecionar tags..."
                          classNamePrefix="select"
                          defaultOptions
                          cacheOptions
                        />
                      </S.FormFieldGroup>
                    </S.TransactionAccordionBody>
                  </S.TransactionAccordionItem>
                </S.TransactionAccordion>
              </S.AccordionWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <S.ManageTransactionsModalFooter>
                <S.FooterButtonGroup>
                  <S.CheckboxWrapper>
                    <S.CheckboxInput
                      type="checkbox"
                      id="conciliated"
                      name="conciliated"
                      checked={validation.values.conciliated}
                      onChange={() =>
                        validation.setFieldValue('conciliated', !validation.values.conciliated)
                      }
                    />
                    <S.CheckboxButton
                      htmlFor="conciliated"
                      className="border "
                      isActive={validation.values.conciliated}
                    >
                      <S.Icon
                        className="mdi mdi-check-all"
                        isActive={validation.values.conciliated}
                      />
                    </S.CheckboxButton>
                  </S.CheckboxWrapper>
                </S.FooterButtonGroup>

                <Button color="success" type="submit" disabled={createIncomeMutation.isPending}>
                  {createIncomeMutation.isPending
                    ? 'Criando...'
                    : `Criar ${TRANSACTION_TYPE_OPTIONS.find(
                        (option) => option.value === transactionType,
                      )?.label}`}
                </Button>
              </S.ManageTransactionsModalFooter>
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};
