import { useCallback } from 'react';
import * as tagsService from '../services/tags';
import * as walletsService from '@modules/Wallets/services';
import * as categoriesService from '../services/categories';
import * as paymentMethodsService from '../services/paymentMethods';

interface ISelectOption {
  value: string;
  label: string;
}

export const useLoadOptions = (transactionType: string) => {
  const loadTagOptions = useCallback(async (inputValue: string): Promise<ISelectOption[]> => {
    try {
      const { tags } = await tagsService.getAll(1, 100);

      return tags
        .filter((tag) => tag.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((tag) => ({
          value: tag.id,
          label: tag.name,
        }));
    } catch (error) {
      console.error('Error loading tags:', error);
      return [];
    }
  }, []);

  const loadWalletOptions = useCallback(async (inputValue: string): Promise<ISelectOption[]> => {
    try {
      const { wallets } = await walletsService.getAll({
        page: 1,
        limit: 100,
        filters: '',
      });

      return wallets
        .filter((wallet) => wallet.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((wallet) => ({
          value: wallet.id,
          label: wallet.name,
        }));
    } catch (error) {
      console.error('Error loading wallets:', error);
      return [];
    }
  }, []);

  const loadCategoryOptions = useCallback(
    async (inputValue: string): Promise<ISelectOption[]> => {
      try {
        const types: Record<string, string> = {
          expense: 'D',
          income: 'R',
        };
        const { categories } = await categoriesService.getAllCategories(1, 100);

        return categories
          .filter(
            (category) =>
              category.name.toLowerCase().includes(inputValue.toLowerCase()) &&
              types[transactionType] === category.type,
          )
          .map((category) => ({
            value: category.id,
            label: category.name,
          }));
      } catch (error) {
        console.error('Error loading categories:', error);
        return [];
      }
    },
    [transactionType],
  );

  const loadPaymentMethodOptions = useCallback(
    async (inputValue: string): Promise<ISelectOption[]> => {
      try {
        const { payment_methods: paymentMethods } = await paymentMethodsService.getAll(1, 100);

        return paymentMethods
          .filter((method) => method.name.toLowerCase().includes(inputValue.toLowerCase()))
          .map((method) => ({
            value: method.id,
            label: method.name,
          }));
      } catch (error) {
        console.error('Error loading payment methods:', error);
        return [];
      }
    },
    [],
  );

  return {
    loadTagOptions,
    loadWalletOptions,
    loadCategoryOptions,
    loadPaymentMethodOptions,
  };
};
