import { financialApiInstance } from '@helpers/request/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import { InstanceError } from '@apptypes/instance';
import {
  IPaymentMethod,
  IPaymentMethodPagination,
  ICreatePaymentMethodPayload,
  IUpdatePaymentMethodPayload,
} from '../types/paymentMethods';

export const getAll = async (page = 1, limit = 10): Promise<IPaymentMethodPagination> => {
  try {
    const { data } = await financialApiInstance.get('/api/v1/payment-methods', {
      params: {
        page,
        limit,
      },
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Métodos de pagamento não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getById = async (id: string): Promise<IPaymentMethod> => {
  try {
    const { data } = await financialApiInstance.get(`/api/v1/payment-methods/${id}`);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Método de pagamento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: ICreatePaymentMethodPayload): Promise<IPaymentMethod> => {
  try {
    const { data } = await financialApiInstance.post('/api/v1/payment-methods', payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Dados inválidos',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  id: string,
  payload: IUpdatePaymentMethodPayload,
): Promise<IPaymentMethod> => {
  try {
    const { data } = await financialApiInstance.put(`/api/v1/payment-methods/${id}`, payload);

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Método de pagamento não encontrado',
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Dados inválidos',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const remove = async (id: string): Promise<void> => {
  try {
    await financialApiInstance.delete(`/api/v1/payment-methods/${id}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Método de pagamento não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
