import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import styled from 'styled-components';

// * [Modal Header] * //
export const ManageTransactionsModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;
export const TransactionTypeInput = styled(Input)`
  border: none;
  font-size: 16px;

  width: 100%;

  padding: 0 32px 0 0;

  cursor: pointer;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  #fixed .form-switch .form-check-input {
    background-image: none !important;
  }
`;
export const FieldOption = styled.option``;

export const FieldWrapper = styled.div<{ marginTop?: number }>`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: ${(props: any) => props.marginTop || 0}px;
`;
export const FormFieldGroup = styled(FormGroup)<{ marginTop?: number }>`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop || 0}px;

  .select__control {
    border-radius: 0.25rem;
    min-height: 35px;
    border-color: #dee2e6;

    &:hover {
      border-color: #dee2e6;
    }
  }

  .select__multi-value {
    background-color: #e9ecef;
    border-radius: 4px;
  }

  .select__multi-value__remove:hover {
    background-color: #dee2e6;
    color: #000;
  }
`;
export const FieldLabel = styled(Label)`
  color: ${({ theme }) => theme.gray500};
`;

export const InputField = styled(Input)``;

export const Icon = styled.span<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? '#fff' : theme.gray500)};
  font-size: 16px;
  margin-right: 4px;
`;

export const ConfigurationTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.gray500};
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TransactionAccordion = styled(Accordion)`
  .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    border-radius: 8px !important;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &::after {
      display: none;
    }

    i,
    span {
      color: ${({ theme }) => theme.white};
    }
  }

  .accordion-body {
    padding: 16px 0 0 0;
  }
`;
export const TransactionAccordionItem = styled(AccordionItem)``;
export const TransactionAccordionHeader = styled(AccordionHeader)``;
export const TransactionAccordionBody = styled(AccordionBody)``;

// * [Modal Footer] * //
export const ManageTransactionsModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: none;
`;

export const InstallmentValue = styled.div`
  padding: 7px 8px;
  background-color: ${({ theme }) => theme.gray100};
  border-radius: 4px;
  color: ${({ theme }) => theme.gray700};
`;

export const FooterButtonGroup = styled.div`
  display: flex;
  gap: 8px;

  button {
    padding: 6px 10px;

    ${Icon} {
      margin: 0;
      font-size: 18px;
    }
  }
`;

export const TransferAccountsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  ${FormFieldGroup} {
    flex: 1;
  }

  .select__control {
    border-radius: 0.25rem;
    min-height: 35px;
    border-color: #dee2e6;

    &:hover {
      border-color: #dee2e6;
    }
  }
`;

export const SwapIcon = styled.i`
  color: ${({ theme }) => theme.gray500};
  font-size: 24px;
  margin-bottom: 16px;
`;

export const CheckboxWrapper = styled.div`
  position: relative;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CheckboxButton = styled.label<{ isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  background-color: ${({ isActive }) => (isActive ? '#34c38f' : '#fff')};
  border: 1px solid #34c38f;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#34c38f' : '#f8f9fa')};
  }

  ${Icon} {
    color: ${({ isActive }) => (isActive ? '#fff' : '#34c38f')};
    margin: 0;
    font-size: 18px;
  }
`;

export const RecurringWrapper = styled.div`
  margin-top: 16px;
`;
