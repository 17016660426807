export const TRANSACTION_VALIDATION_MESSAGES = {
  DESCRIPTION_REQUIRED: 'Descrição é obrigatória',
  AMOUNT_REQUIRED: 'Valor é obrigatório',
  PAYMENT_DATE_REQUIRED: 'Data de pagamento é obrigatória',
  WALLET_REQUIRED: 'Conta/Cartão é obrigatório',
  CATEGORY_REQUIRED: 'Categoria é obrigatória',
  PAYMENT_METHOD_REQUIRED: 'Forma de pagamento é obrigatória',
  PERIODICITY_REQUIRED: 'Periodicidade é obrigatória',
  INSTALLMENTS_REQUIRED: 'Número de parcelas é obrigatório',
  INSTALLMENTS_MIN: 'Mínimo de 2 parcelas',
} as const;
